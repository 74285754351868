import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import FdfmDaySection from "./FdfmDaySection"
import { fdfmData } from "../../data/FdfmData"
import DisclaimerBar2 from "../main/DisclaimerBar2"

import { FaThumbsUp } from "react-icons/fa"
import { FaThumbsDown } from "react-icons/fa"
import { BsFillQuestionCircleFill } from "react-icons/bs"

import FdfmLogo from "../../assets/images/fdfm/logo.jpeg"
import Stars from "../../assets/images/fdfm/five-stars.png"
import Included1 from "../../assets/images/fdfm/included-1.png"
import Included2 from "../../assets/images/fdfm/included-2.png"
import Included3 from "../../assets/images/fdfm/included-3.png"
import Included4 from "../../assets/images/fdfm/included-4.png"
import Instructors from "../../assets/images/fdfm/meet-instructors.png"
import FbTestimonial1 from "../../assets/images/fdfm/FbTestimonial1.jpeg"
import FbTestimonial2 from "../../assets/images/fdfm/FbTestimonial2.jpeg"
import FbTestimonial3 from "../../assets/images/fdfm/FbTestimonial3.jpeg"
import FbTestimonial4 from "../../assets/images/fdfm/FbTestimonial4.jpeg"
import FbTestimonial5 from "../../assets/images/fdfm/FbTestimonial5.jpeg"
import FbTestimonial6 from "../../assets/images/fdfm/FbTestimonial6.jpeg"
import FbTestimonial7 from "../../assets/images/fdfm/FbTestimonial7.jpeg"
import FbTestimonial8 from "../../assets/images/fdfm/FbTestimonial8.jpeg"
import ClientsLogos from "../../assets/images/fdfm/client-logos.jpeg"
import Faq from "../../assets/images/fdfm/faq.svg"
import SatisfactionGuarantee from "../../assets/images/fdfm/satisfaction-guarantee.png"
import YouTubeIframe from "../YouTubeIframe"
import favicon from "../../assets/images/fdfm/favicon.png"

export default function Fdfm() {
  return (
    <>
      <Helmet
        title="14 Day Filmmaker | Learn Pro Content Creation In Just 14 Days"
        meta={[
          {
            name: "description",
            content:
              "14 Day Filmmaker is for anyone who wants to produce cinematic & emotional videos without wasting months trying to figure it all out on your own. The Best Online Courses For Learning Content Creation. Commit to daily growth by enrolling in immersive, online classes with the worlds best instructors. Unlock your creative potential.",
          },
          { name: "keywords", content: "...." },
        ]}
        link={[{ rel: "shortcut icon", type: "image/png", href: `${favicon}` }]}
      />
      <Header>
        <BasicImage1
          src={FdfmLogo}
          alt="Next Level Creators Logo"
          style={{ height: "50px" }}
        />
      </Header>
      <section className="themes__panel-light">
        <Container className="themes__panel-light themes__flex-center-all">
          <ContentContainer2>
            <Row>
              <BasicH1 style={{ margin: "25px 0 25px 0" }}>
                Learn Professional Content Creation In Just 14 Days
              </BasicH1>
            </Row>
            <Row className="d-flex justify-content-center align-items-center">
              <Col
                md={6}
                className="d-flex justify-content-center align-items-center fdfm__iframe-format"
              >
                <IframeEmbed iclass="embed-responsive embed-responsive-16by9">
                  <iframe
                    title="Free Training"
                    src="https://fast.wistia.net/embed/iframe/p7zo4b0kmt?seo=false&videoFoam=true"
                    className="free-training-1__responsive-iframe"
                    style={{ background: "black" }}
                    allowFullScreen
                  ></iframe>
                </IframeEmbed>
              </Col>
              <Col
                md={6}
                className="d-flex justify-content-center align-items-center flex-wrap"
              >
                <BasicH3>
                  <b>14-Day Filmmaker</b> is for anyone who wants to produce
                  cinematic & emotional videos without wasting months trying to
                  figure it all out on yor own. Right now, get lifetime access
                  for just $48 <em>(Normally $198)</em>. Offer Expires Soon.
                </BasicH3>
                <ButtonContainer>
                  <a
                    href="https://www.contentcreator.com/offers/noLFkJbK/checkout"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="btn btn-primary btn-large">
                      Start 14-Day Filmmaker Today
                    </button>
                  </a>
                </ButtonContainer>
              </Col>
            </Row>
          </ContentContainer2>
        </Container>
      </section>
      <section className="themes__panel-red">
        <Container className="themes__panel-red">
          <Row className="d-flex justify-content-between pt-3 pb-3">
            <Col className="d-flex justify-content-center align-content-center flex-wrap">
              <BasicImage1
                src={Stars}
                alt="5 Star"
                style={{ height: "3rem" }}
              />
              <BasicP style={{ textAlign: "center", color: "white" }}>
                <i>
                  "I'm taking this course, and it's worth 10 times what they're
                  charging for it. Great value and excellent presentation." -
                  Randy B
                </i>
              </BasicP>
            </Col>
            <Col className="d-flex justify-content-center align-content-start flex-wrap">
              <BasicImage1
                src={Stars}
                alt="5 Star"
                style={{ height: "3rem" }}
              />
              <BasicP style={{ textAlign: "center", color: "white" }}>
                <i>
                  "I’m enrolled in 3 other cinematography classes besides this
                  course and I want to thank you for walking us step-by-step
                  through this process. I’ve learned more from your classes in
                  less than 14 days than 2 years of college." - Sinakhone K..
                </i>
              </BasicP>
            </Col>
            <Col className="d-flex justify-content-center align-content-start flex-wrap">
              <BasicImage1
                src={Stars}
                alt="5 Star"
                style={{ height: "3rem" }}
              />
              <BasicP style={{ textAlign: "center", color: "white" }}>
                <i>
                  "No wasted words, just valuable and essential information.
                  Taught in a very understandable manner. Even for me, a
                  professional photographer with over 30 years of experience." -
                  David M.
                </i>
              </BasicP>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="themes__panel-light">
        <Container className="themes__panel-light">
          <Row>
            <BasicH1 style={{ margin: "30px 0 0 0", width: "100%" }}>
              14-Day Filmmaker Agenda
            </BasicH1>
          </Row>
          <Row className="justify-content-center">
            <ExplainerHeading>
              Learn the fundamental laws of cinematic & emotional filmmaking in
              the fastest timeframe possible
            </ExplainerHeading>
          </Row>
          <Row>
            <div className="fdfm__line-separator"></div>
          </Row>
        </Container>
      </section>
      {fdfmData.map(day => (
        <FdfmDaySection key={day.id} day={day} />
      ))}
      <section className="themes__panel-light">
        <Container className="themes__panel-light">
          <Row className="d-flex justify-content-between">
            <Col className="d-flex justify-content-center align-content-start flex-wrap">
              <ButtonContainer style={{ margin: "40px 0" }}>
                <a
                  href="https://www.contentcreator.com/offers/noLFkJbK/checkout"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btn btn-primary btn-large">
                    Start 14-Day Filmmaker Today
                  </button>
                </a>
              </ButtonContainer>
            </Col>
          </Row>
        </Container>
      </section>
      <WhatIncludedSection className="themes__panel-red">
        <Container className="themes__panel-red">
          <Row className="d-flex justify-content-center align-content-center">
            <WhatIncludedH1>What's Included In 14 Day Filmmaker</WhatIncludedH1>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex justify-content-center align-content-start flex-wrap">
              <WhatIncludedContainer className="themes__panel-light">
                <WhatIncludedImage src={Included1} alt="What is included?" />
                <div className="fdfm-section__text-container80">
                  <WhatIncludedP>
                    This program gives you an in-depth look at how we shoot,
                    compose, choose camera settings, create movement, story
                    tell, edit, and organically release commercials that
                    generate profit.
                  </WhatIncludedP>
                </div>
              </WhatIncludedContainer>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex justify-content-center align-content-start flex-wrap">
              <WhatIncludedContainer className="themes__panel-light">
                <WhatIncludedImage src={Included2} alt="What is included?" />
                <div className="fdfm-section__text-container80">
                  <WhatIncludedP>
                    This is the first ever speed-learning program for developing
                    real video production skills. Instead of just having video
                    tutorials, you will be challenged with a 1-hour daily
                    exercise designed to develop your skills. As we like to say:
                    practice makes perfect, not consumption!
                  </WhatIncludedP>
                </div>
              </WhatIncludedContainer>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex justify-content-center align-content-start flex-wrap">
              <WhatIncludedContainer className="themes__panel-light">
                <WhatIncludedImage src={Included3} alt="What is included?" />
              </WhatIncludedContainer>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex justify-content-center align-content-start flex-wrap">
              <WhatIncludedContainer className="themes__panel-light">
                <WhatIncludedImage src={Included4} alt="What is included?" />
              </WhatIncludedContainer>
            </Col>
          </Row>
        </Container>
      </WhatIncludedSection>
      <section className="themes__panel-light">
        <Container className="themes__panel-light">
          <Row className="d-flex justify-content-between">
            <Col className="d-flex justify-content-center align-content-start flex-wrap">
              <ContentContainer1
                className="themes__panel-light"
                style={{ margin: "5% 0 5% 0", width: "95%" }}
              >
                <MeetInstructorsImage
                  src={Instructors}
                  alt="What is included?"
                />
                <div className="fdfm-section__text-container80">
                  <BasicP>
                    We are <b>Commercial Filmmakers</b>. Telling passionate
                    entrepreneurs stories with video is what we do
                    every-single-day.
                  </BasicP>
                  <BasicP>
                    We started shooting content in 2013 with a Canon 70d and a
                    $50 lens.
                  </BasicP>
                  <BasicP>
                    Since then, we’ve shot over 200 commercials combined which
                    have generated our clients tens of millions in profit.
                  </BasicP>
                  <BasicP>
                    We still run our vieo agency today alongside building the
                    most effective online-programs for content creators that
                    want RESULTS.
                  </BasicP>
                </div>
              </ContentContainer1>
            </Col>
          </Row>
        </Container>
      </section>
      <section
        className="themes__panel-light-gray"
        style={{ padding: "0 0 5% 0" }}
      >
        <Container className="themes__panel-light-gray">
          <Row>
            <BasicH1 style={{ margin: "25px 0 0 0" }}>
              Some Of Our Recent Work:
            </BasicH1>
          </Row>
          <Row>
            <Col md={6} className="p-3">
              <YouTubeIframe
                youtubeId="72x_EBwyoJM"
                title="How To Shoot A Profitable Commercial | Cinematography Breakdown"
              />
            </Col>
            <Col md={6} className="p-3">
              <YouTubeIframe
                youtubeId="f9ScgMEkE0s"
                title="How To Shoot 5 Commercials In 5 Hours | Behind The Scenes"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} className="p-3">
              <YouTubeIframe
                youtubeId="GXIlNFcSLug"
                title="SHOOT BETTER VIDEOS with this ONE SIMPLE TIP"
              />
            </Col>
            <Col md={6} className="p-3">
              <YouTubeIframe
                youtubeId="LvqINV762xs"
                title="Shooting An Epic MTB Commercial | Best Mountain Biking In Maine"
              />
            </Col>
            <Row>
              <Spacer2 />
            </Row>
          </Row>
        </Container>
      </section>
      <section className="themes__panel-light">
        <Container className="themes__panel-light">
          <Row className="d-flex justify-content-between">
            <Col className="d-flex justify-content-center align-content-start flex-wrap">
              <ButtonContainer style={{ margin: "40px 0" }}>
                <a
                  href="https://www.contentcreator.com/offers/noLFkJbK/checkout"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btn btn-primary btn-large">
                    Enroll In 14-Day Filmmaker Today
                  </button>
                </a>
              </ButtonContainer>
            </Col>
          </Row>
        </Container>
      </section>
      <section
        className="themes__panel-red"
        style={{ padding: "30px 0 20px 0" }}
      >
        <Container className="themes__panel-red">
          <Row className="d-flex justify-content-between">
            <Col className="d-flex justify-content-center align-content-start flex-wrap">
              <WhatIncludedContainer className="themes__panel-light">
                <div className="fdfm-section__text-container90">
                  <BasicH1 style={{ margin: "20px 0 20px 0" }}>
                    Who This Course Is For...
                  </BasicH1>
                  <BasicP>
                    <ThumbsUpIcon />
                    You want to learn how to shoot, direct & edit cinematic
                    commercials that produce real profits - or you want to learn
                    to do it better.
                  </BasicP>
                  <BasicP>
                    <ThumbsUpIcon />
                    We’ll be starting from the ground up so the program can take
                    you from zero to hero—however, this program is also great
                    for experienced content creators who want to learn Next
                    Level Creators methods for how to actually SHOOT high
                    quality video.
                  </BasicP>
                  <BasicP>
                    <ThumbsUpIcon />
                    You're willing to put in some work - this program works when
                    you do. If you plan to invest in the program and get to it
                    whenever you get to it, Youtube is a better place to
                    procrastinate.
                  </BasicP>
                </div>
              </WhatIncludedContainer>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex justify-content-center align-content-start flex-wrap">
              <WhatIncludedContainer className="themes__panel-light">
                <div className="fdfm-section__text-container90">
                  <BasicH1 style={{ margin: "20px 0 20px 0" }}>
                    Who This Course Is NOT For...
                  </BasicH1>
                  <BasicP>
                    <ThumbsDownIcon />
                    You are looking for a comprehensive program on video
                    distribution & paid advertising on Facebook, Instagram,
                    Youtube, Google, etc... This program has one day that that
                    goes in-depth into&nbsp;
                    <u>organic (free) video distribution</u>
                    &nbsp;strategies but paid advertising will not be covered.
                  </BasicP>
                  <BasicP>
                    <ThumbsDownIcon />
                    You do not care about speed. This program is developed for
                    people that want to develop this incredibly valuable
                    skillset in the&nbsp;
                    <u>fasted timeframe possible</u>.&nbsp;There are better
                    programs out there for those of you who have an infinite
                    amount of time and no real need to move quickly.
                  </BasicP>
                </div>
              </WhatIncludedContainer>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="themes__panel-light">
        <Container className="themes__panel-light">
          <Row className="d-flex justify-content-center">
            <Col
              md={6}
              className="d-flex justify-content-center align-content-start flex-wrap"
              style={{ width: "100%" }}
            >
              <BasicH1 style={{ margin: "30px 0 25px 0" }}>
                Content Creators Who LOVE 14 Day Filmmaker
              </BasicH1>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md={6} className="fdfm__column-80-95">
              <div className="iframe-container">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/P-W6nB5VcJw"
                  title="YouTube video player"
                  style={{ border: "0" }}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center pt-4">
            <Col
              md={6}
              className="d-flex justify-content-center align-content-start flex-wrap"
            >
              <BasicImage4
                src={FbTestimonial1}
                alt="Testimonial"
                pagespeed_no_transform
              />
              <BasicImage4
                src={FbTestimonial2}
                alt="Testimonial"
                pagespeed_no_transform
              />
              <BasicImage4
                src={FbTestimonial3}
                alt="Testimonial"
                pagespeed_no_transform
              />
              <BasicImage4
                src={FbTestimonial4}
                alt="Testimonial"
                pagespeed_no_transform
              />
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-center align-content-start flex-wrap"
            >
              <BasicImage4
                src={FbTestimonial5}
                alt="Testimonial"
                pagespeed_no_transform
              />
              <BasicImage4
                src={FbTestimonial6}
                alt="Testimonial"
                pagespeed_no_transform
              />
              <BasicImage4
                src={FbTestimonial7}
                alt="Testimonial"
                pagespeed_no_transform
              />
              <BasicImage4
                src={FbTestimonial8}
                alt="Testimonial"
                pagespeed_no_transform
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-center pt-4">
            <Col
              md={6}
              className="d-flex justify-content-center align-content-start"
              style={{ width: "90%" }}
            >
              <BasicImage4 src={ClientsLogos} alt="Testimonial" />
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex justify-content-center align-content-start flex-wrap">
              <ButtonContainer style={{ margin: "30px 0" }}>
                <a
                  href="https://www.contentcreator.com/offers/noLFkJbK/checkout"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btn btn-primary btn-large">
                    Enroll In 14-Day Filmmaker Today!
                  </button>
                </a>
              </ButtonContainer>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="themes__panel-red">
        <Container className="themes__panel-red">
          <Row className="d-flex justify-content-center">
            <QandAImage src={Faq} alt="Testimonial" />
            <BasicH1
              style={{
                color: "white",
                margin: "40px 0 30px 0",
              }}
            >
              Frequently Asked Questions
            </BasicH1>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex justify-content-center align-content-start flex-wrap">
              <QandAContainer className="themes__panel-light">
                <div className="fdfm-section__text-container90">
                  <QandAIcon />
                  <QandAH4>
                    How long do I have access to the program & when does it
                    start?
                  </QandAH4>
                </div>
                <div className="fdfm-section__text-container90">
                  <QandAP>How does lifetime access sound?</QandAP>
                  <QandAP>
                    After enrolling, you have unlimited access to this program
                    for as long as you like - across any and all devices you
                    own. Inside the program there are 14-days of skill
                    developing content + exercises.
                  </QandAP>
                </div>
              </QandAContainer>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex justify-content-center align-content-start flex-wrap">
              <QandAContainer className="themes__panel-light">
                <div className="fdfm-section__text-container90">
                  <QandAH4>
                    <QandAIcon />
                    Can't I learn all of this on Youtube?
                  </QandAH4>
                </div>
                <div className="fdfm-section__text-container90">
                  <QandAP>
                    Youtube is a fantastic resource with more free tutorials
                    than any individual is capable of watching on improving your
                    film skills.
                  </QandAP>
                  <QandAP>
                    We even produce 20 minutes to 1 hour worth of free tutorials
                    for filmmakers every week for Youtube!
                  </QandAP>
                  <QandAP>
                    But a side effect of only learning on Youtube is that you'll
                    waste dozens of hours learning the same thing, searching for
                    tutorials only to find partial answers and not having
                    precise exercises designed to help you truly learn the
                    skills you're after.
                  </QandAP>
                  <QandAP>
                    If you want to develop your skills FAST, then 14 Day
                    Filmmaker is for you.
                  </QandAP>
                  <QandAP>Plus, what is your time worth?</QandAP>
                  <QandAP>
                    This program will save you weeks of frustrated time
                    searching for answers and rapidly increase your content
                    creation skills.
                  </QandAP>
                  <QandAP>For some - thats $48 well spent!</QandAP>
                  <QandAP>If your time isn't worth that yet...</QandAP>
                  <QandAP>Stick to Youtube until it is!</QandAP>
                </div>
              </QandAContainer>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex justify-content-center align-content-start flex-wrap">
              <QandAContainer className="themes__panel-light">
                <div className="fdfm-section__text-container90">
                  <QandAH4>
                    <QandAIcon />
                    Should I invest in 14 Day Filmmaker before investing in
                    camera gear?
                  </QandAH4>
                </div>
                <div className="fdfm-section__text-container90">
                  <QandAP>That depends on you...</QandAP>
                  <QandAP>
                    Which do you believe would be worth more to you:
                  </QandAP>
                  <QandAP>
                    1. Buying just one expensive piece of equipment then
                    spending hours scouring Youtube trying to learn how to
                    master it with zero step-by-step skill developing practice
                    exercises?
                  </QandAP>
                  <QandAP>
                    2. Investing just $48 today developing the skills,
                    resourcefulness strategies and creative approaches to
                    succeed with any gear you can buy or rent in the future?
                  </QandAP>
                  <QandAP>
                    Inside 14-day filmmaker we breakdown the the exact gear to
                    invest in for different types of commercial filmmaking and
                    give different options for your budget.
                  </QandAP>
                  <QandAP>
                    Professional filmmakers typically agree that having skills
                    are more important than having expensive equipment.
                  </QandAP>
                  <QandAP>
                    But at the end of the day, it's your decision my friend!
                  </QandAP>
                </div>
              </QandAContainer>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex justify-content-center align-content-start flex-wrap">
              <QandAContainer className="themes__panel-light">
                <div className="fdfm-section__text-container90">
                  <QandAH4>
                    <QandAIcon />
                    What skill level is this program for?
                  </QandAH4>
                </div>
                <div className="fdfm-section__text-container90">
                  <QandAP>
                    14 Day Filmmaker was designed to take somebody from knowing
                    nothing about shooting with a camera --&gt; to being able to
                    produce cinematic commercials.
                  </QandAP>
                  <QandAP>
                    Not just that, it also will teach you how to shoot
                    commercials that generate massive profits, for yourself and
                    your clients.
                  </QandAP>
                  <QandAP>
                    The most common word used to describe 14 Day Filmmaker is
                    "FUN".
                  </QandAP>
                  <QandAP>
                    We have helped filmmakers at both ends of the skill
                    spectrum.
                  </QandAP>
                </div>
              </QandAContainer>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex justify-content-center align-content-start flex-wrap">
              <QandAContainer className="themes__panel-light">
                <SatisfactionGuaranteeImage
                  src={SatisfactionGuarantee}
                  alt="Satisfaction Guaranteed"
                />
                <div className="fdfm-section__text-container90">
                  <QandAH4 style={{ textIndent: "0" }}>
                    <QandAIcon />
                    Is there a guarantee?
                  </QandAH4>
                </div>
                <div className="fdfm-section__text-container90">
                  <QandAP>
                    Absolutely! 14 Day Filmmaker has a 7-Day Satisfaction
                    Guarantee. If you are not completely SATISFIED and
                    ENLIGHTENED by the 14 Day Filmmaker Program, then contact us
                    within 7 Days for a full refund, no questions asked!
                  </QandAP>
                </div>
              </QandAContainer>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="themes__panel-light">
        <Container className="themes__panel-light">
          <Row className="d-flex justify-content-between">
            <Col className="d-flex justify-content-center align-content-start flex-wrap">
              <ButtonContainer style={{ margin: "40px 0" }}>
                <a
                  href="https://www.contentcreator.com/offers/noLFkJbK/checkout"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btn btn-primary btn-large">
                    Start 14-Day Filmmaker Today
                  </button>
                </a>
              </ButtonContainer>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="themes__panel-light">
        <HorizontalLine style={{ height: "5px" }} />
      </section>
      <DisclaimerBar2 />
    </>
  )
}

const WhatIncludedSection = styled.section`
  padding-bottom: 25px;

  @media screen and (max-width: 768px) {
    padding-bottom: 5px;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  height: 80px;
`
const ContentContainer1 = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`
const ContentContainer2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: left;
  width: 100%;
  padding-bottom: 20px;
`
const WhatIncludedContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 5% 2% 5%;
  width: 830px;
  border-radius: 5px;

  @media screen and (max-width: 768px) {
    max-width: 830px;
    margin: 0 2% 5% 2%;
  }
`
const QandAContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 5% 2% 5%;
  width: 830px;
  border-radius: 5px;
  position: relative;

  @media screen and (max-width: 768px) {
    max-width: 830px;
    margin: 0 2% 5% 2%;
  }
`

const IframeEmbed = styled.div`
  position: relative;
  overflow: hidden;
  width: 120%;
  padding-top: 56.25%;
  z-index: 100;
  box-shadow: 1px 1px 16px 5px #888890;
`

const HorizontalLine = styled.div`
  width: 100vw;
  border-top: 1px solid black;
`

const BasicH1 = styled.h1`
  font-family: "Open Sans";
  line-height: 57.6px;
  font-size: 48px;
  text-align: center;
  font-weight: 700;
  color: black;
  -webkit-font-smoothing: antialiased;

  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 33.6px;
  }
`

const BasicH3 = styled.h3`
  font-family: "Open Sans", "Roboto", Arial, Helvetica, sans-serif;
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  color: rgb(89, 89, 89);
  letter-spacing: normal;
  margin: 25px 0;
  width: 80%;
  line-height: 32px;
  text-align: center;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 25.6px;
  }
`

const WhatIncludedH1 = styled.h1`
  font-family: "Open Sans";
  line-height: 57.6px;
  font-size: 48px;
  text-align: center;
  font-weight: 700;
  color: white;
  -webkit-font-smoothing: antialiased;
  padding: 30px 0 30px 0;

  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 33.6px;
    padding: 40px 30px 20px 30px;
  }
`

const ExplainerHeading = styled.h3`
  font-family: "Open Sans", "Roboto", Arial, Helvetica, sans-serif;
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  color: rgb(89, 89, 89);
  letter-spacing: normal;
  margin: 25px 0;
  width: 100%;
  line-height: 32px;
  text-align: center;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  @media screen and (max-width: 768px) {
    font-size: 22px;
    line-height: 35.2px;
  }
`
const QandAH4 = styled.h4`
  display: block;
  width: 100%;
  font-family: "Open Sans", "Roboto", Arial, Helvetica, sans-serif;
  font-size: 36px;
  font-weight: 700;
  color: rgb(22, 30, 42);
  line-height: 43.2px;
  overflow-wrap: break-word;
  text-align: center;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  margin: 35px 0;
  text-indent: 20px;

  @media screen and (max-width: 420px) {
    font-size: 30px;
    line-height: 36px;
    margin: 30px 0;
    text-indent: 30px;
  }
`

const ButtonContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`

const BasicImage1 = styled.img``

const BasicImage4 = styled.img`
  max-width: 100%;
  width: 100%;
  height: auto;
  display: block;
  box-sizing: border-box;
  margin-bottom: 8px;
  border-radius: 4px;
  border-bottom-style: none;
  border-width: 0;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-color: rgb(89, 89, 89);
  border-style: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  --webkit-box-direction: normal;
`
const WhatIncludedImage = styled.img`
  width: 90%;

  @media screen and (max-width: 768px) {
  }
`
const QandAImage = styled.img`
  width: 33%;
  margin: 30px 0 0 0;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const MeetInstructorsImage = styled.img`
  width: 90%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const SatisfactionGuaranteeImage = styled.img`
  width: 90%;
  margin-top: 30px;
`

const BasicP = styled.p`
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  text-align: left;
  color: rgb(89, 89, 89);
  font-size: 20px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  padding-left: 26px;
  overflow-wrap: break-word;
  text-rendering: optimizeLegibility;
  -webkit-box-direction: normal;
  -webkit-font-smoothing: antialiased;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 25.6px;
    padding-left: 20.8px;
  }
`
const WhatIncludedP = styled.p`
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  text-align: center;
  color: rgb(89, 89, 89);
  font-size: 20px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  padding: 10px 40px 10px 40px;
  overflow-wrap: break-word;
  text-rendering: optimizeLegibility;
  -webkit-box-direction: normal;
  -webkit-font-smoothing: antialiased;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    padding: 10px 20px 10px 20px;
    line-height: 25.6px;
  }
`
const QandAP = styled.p`
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  text-align: left;
  color: rgb(89, 89, 89);
  font-size: 20px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  margin-bottom: 16px;
  overflow-wrap: break-word;
  text-rendering: optimizeLegibility;
  -webkit-box-direction: normal;
  -webkit-font-smoothing: antialiased;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 25.6px;
  }
`
const Spacer2 = styled.div`
  height: 20px;
`
const ThumbsUpIcon = styled(FaThumbsUp)`
  font-size: 1.7rem;
  color: green;
  margin: 0 9px 8px 0;
`
const ThumbsDownIcon = styled(FaThumbsDown)`
  font-size: 1.7rem;
  color: red;
  margin: 0 9px 8px 0;
`
const QandAIcon = styled(BsFillQuestionCircleFill)`
  font-size: 50px;
  color: rgb(89, 89, 89);
  position: absolute;
  top: 10px;
  left: 10px;
  right: 0;
  bottom: 0;

  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`
