import Day1Image from "../assets/images/fdfm/day-1.gif"
import Day2Image from "../assets/images/fdfm/day-2.gif"
import Day3Image from "../assets/images/fdfm/day-3.gif"
import Day4Image from "../assets/images/fdfm/day-4.gif"
import Day5Image from "../assets/images/fdfm/day-5.gif"
import Day6Image from "../assets/images/fdfm/day-6.gif"
import Day7Image from "../assets/images/fdfm/day-7.gif"
import Day8Image from "../assets/images/fdfm/day-8.gif"
import Day9Image from "../assets/images/fdfm/day-9.gif"
import Day10Image from "../assets/images/fdfm/day-10.gif"
import Day11Image from "../assets/images/fdfm/day-11.gif"
import Day12Image from "../assets/images/fdfm/day-12.gif"
import Day13Image from "../assets/images/fdfm/day-13.gif"
import Day14Image from "../assets/images/fdfm/day-14.gif"

// import Day1Video from "../assets/videos/fdfm/Day 1.mp4"
// import Day2Video from "../assets/videos/fdfm/Day 2.mp4"
// import Day3Video from "../assets/videos/fdfm/Day 3.mp4"
// import Day4Video from "../assets/videos/fdfm/Day 4.mp4"
// import Day5Video from "../assets/videos/fdfm/Day 5.mp4"
// import Day6Video from "../assets/videos/fdfm/Day 6.mp4"
// import Day7Video from "../assets/videos/fdfm/Day 7.mp4"
// import Day8Video from "../assets/videos/fdfm/Day 8.mp4"
// import Day9Video from "../assets/videos/fdfm/Day 9.mp4"
// import Day10Video from "../assets/videos/fdfm/Day 10.mp4"
// import Day11Video from "../assets/videos/fdfm/Day 11.mp4"
// import Day12Video from "../assets/videos/fdfm/Day 12.mp4"
// import Day13Video from "../assets/videos/fdfm/Day 13.mp4"
// import Day14Video from "../assets/videos/fdfm/Day 14.mp4"

export const fdfmData = [
  {
    id: 1,
    class: "fdfm__panel-light",
    img: Day1Image,
    alt: "Day 1",
    name: "day1",
    title: "Day 1: Fundamentals, Gear & Camera Settings",
    details: [
      {
        id: 1,
        detail:
          "What you should look for when buying a camera, lenses, lights, mics, and so on.",
      },
      {
        id: 2,
        detail:
          "The exact gear you should buy depending on your budget and goals",
      },
      {
        id: 3,
        detail: "The fundamental camera settings for quality videos",
      },
      {
        id: 4,
        detail:
          "1-Hour Exercise: Content quiz and practicing with what you have",
      },
    ],
  },
  {
    id: 2,
    img: Day2Image,
    class: "fdfm__panel-light-gray",
    alt: "Day 2",
    name: "day3",
    title: "Day 2: Focal Lengths & Lenses",
    details: [
      {
        id: 1,
        detail: "The different types of lenses on.",
      },
      {
        id: 2,
        detail: "What you should look for when purchasing lenses",
      },
      {
        id: 3,
        detail: "Frame rate tutorials: How to get the best slow motion footage",
      },
      {
        id: 4,
        detail: "Getting creative with focal lengths",
      },
      {
        id: 5,
        detail:
          "1-Hour Exercise: Focal length challenge & practice foundational camera settings",
      },
    ],
  },
  {
    id: 3,
    class: "fdfm__panel-light",
    img: Day3Image,
    alt: "Day 3",
    name: "day3",
    title: "Day 3: Composition",
    details: [
      {
        id: 1,
        detail: "Ideal Video composition",
      },
      {
        id: 2,
        detail: "10 primary camera angles",
      },
      {
        id: 3,
        detail: "Composition that evokes emotion",
      },
      {
        id: 4,
        detail: "Make boring things look awesome with movement",
      },
      {
        id: 5,
        detail:
          "1-Hour Exercise: Shoot 10 cinematic movements & recreate 5 composition shot",
      },
    ],
  },
  {
    id: 4,
    class: "fdfm__panel-light-gray",
    img: Day4Image,
    alt: "Day 4",
    name: "day4",
    title: "Day 4: Cinematic Movements",
    details: [
      {
        id: 1,
        detail:
          "How to increase the quality of your videos by adding cinematic movements",
      },
      {
        id: 2,
        detail: "Handheld movements techniques",
      },
      {
        id: 3,
        detail: "Tripod movement techniques",
      },
      {
        id: 4,
        detail:
          "The different tools (steadicams, gimbals, etc.) and how to use them",
      },
      {
        id: 5,
        detail: "1-Hour Exercise: Practical exercises for learning movement",
      },
    ],
  },
  {
    id: 5,
    class: "fdfm__panel-light",
    img: Day5Image,
    alt: "Day 5",
    name: "day5",
    title: "Day 5: Lighting",
    details: [
      {
        id: 1,
        detail: "Fundamentals of lighting",
      },
      {
        id: 2,
        detail: "10 primary camera angles",
      },
      {
        id: 3,
        detail: "White balance",
      },
      {
        id: 4,
        detail: "Split lighting",
      },
      {
        id: 5,
        detail: "The 3 point setup",
      },
      {
        id: 6,
        detail: "Color temperature",
      },
      {
        id: 7,
        detail: "How to get cinematic lighting in a difficult environments",
      },
      {
        id: 8,
        detail: "Creative lighting techniques",
      },
      {
        id: 9,
        detail: "1-Hour Exercise: Practice the 3 point lighting setup",
      },
    ],
  },
  {
    id: 6,
    class: "fdfm__panel-light-gray",
    img: Day6Image,
    alt: "Day 6",
    name: "day6",
    title: "Day 6: Sound Design",
    details: [
      {
        id: 1,
        detail: "Capturing clean audio",
      },
      {
        id: 2,
        detail: "Professional sound design",
      },
      {
        id: 3,
        detail: "Finding & selecting sound effects",
      },
      {
        id: 4,
        detail:
          "1-Hour Exercise: Downloadable practice material + practical exercises",
      },
    ],
  },
  {
    id: 7,
    class: "fdfm__panel-light",
    img: Day7Image,
    alt: "Day 7",
    name: "day7",
    title: "Day 7: File Management + Intro To Editing",
    details: [
      {
        id: 1,
        detail: "Staying organized",
      },
      {
        id: 2,
        detail:
          "How to save hours editing with naming conventions and a file tree",
      },
      {
        id: 3,
        detail: " File storage and backup",
      },
      {
        id: 4,
        detail:
          "1-Hour Exercise: Create your file tree, organize all content shot thus far with naming conventions & breakdown five commercials lighting setups",
      },
    ],
  },
  {
    id: 8,
    class: "fdfm__panel-light-gray",
    img: Day8Image,
    alt: "Day 8",
    name: "day8",
    title: "Day 8: Professional Editing Walkthrough",
    details: [
      {
        id: 1,
        detail:
          "Learn how to edit in: DaVinci Resolve, Adobe Premiere Pro or Final Cut Pro",
      },
      {
        id: 2,
        detail: "Full walkthrough of each editing software",
      },
      {
        id: 3,
        detail: "Choose whichever program you like!",
      },
      {
        id: 4,
        detail:
          "1-Hour Exercise: Download the example footage and follow over-the-shoulder editing tutorial to recreate final commercial",
      },
    ],
  },
  {
    id: 9,
    class: "fdfm__panel-light",
    img: Day9Image,
    alt: "Day 9",
    name: "day9",
    title: "Day 9: Intro To After Effects",
    details: [
      {
        id: 1,
        detail: "Adobe After Effects animations",
      },
      {
        id: 2,
        detail: "Keyframing in after effects",
      },
      {
        id: 3,
        detail: "Creating and using Motion Graphics templates",
      },
      {
        id: 4,
        detail: "Separate yourself from the crowd with motion graphics",
      },
      {
        id: 5,
        detail:
          "1-Hour Exercise: Download practice commercial and remake all the graphics in the tutorial",
      },
    ],
  },
  {
    id: 10,
    class: "fdfm__panel-light-gray",
    img: Day10Image,
    alt: "Day 10",
    name: "day10",
    title: "Day 10: Virtual Job Shadows",
    details: [
      {
        id: 1,
        detail: "Follow us on live commercial sets.",
      },
      {
        id: 2,
        detail: "Learn how to direct talent",
      },
      {
        id: 3,
        detail: "Understanding both sides of the camera",
      },
      {
        id: 4,
        detail: "Observe our start to finish process in action.",
      },
      {
        id: 5,
        detail:
          "Commercial breakdowns: Behind the scenes of profitable video advertising",
      },
      {
        id: 6,
        detail: "1-Hour Exercise: Film a creative lighting scene",
      },
    ],
  },
  {
    id: 11,
    class: "fdfm__panel-light",
    img: Day11Image,
    alt: "Day 11",
    name: "day11",
    title: "Day 11: Scriptwriting & Using A Storyboard",
    details: [
      {
        id: 1,
        detail: "What is a value video?",
      },
      {
        id: 2,
        detail: "Basics of scriptwriting",
      },
      {
        id: 3,
        detail: "Keys to developing an effective storyboard",
      },
      {
        id: 4,
        detail: "Creative ways to get attention",
      },
      {
        id: 5,
        detail: "Pre-production process for planning your commercial shoots",
      },
      {
        id: 6,
        detail: "Watch us write a script for 14-day filmmaker",
      },
      {
        id: 7,
        detail:
          "1-Hour Exercise: Write your first script and develop the storyboard",
      },
    ],
  },
  {
    id: 12,
    class: "fdfm__panel-light-gray",
    img: Day12Image,
    alt: "Day 12",
    name: "day12",
    title: "Day 12: Shoot Your Commercial",
    details: [
      {
        id: 1,
        detail: "Day of preparation",
      },
      {
        id: 2,
        detail: "7 strategies for finding on camera talent (for free)",
      },
      {
        id: 3,
        detail: "How to shoot mouth-watering B-roll",
      },
      {
        id: 4,
        detail: "Getting into state for leading the shoot and winning the day",
      },
      {
        id: 5,
        detail: "Things to remember, getting the shots, extra b-roll tips",
      },
      {
        id: 6,
        detail: "Watch us shoot the 14-day filmmaker commercial",
      },
      {
        id: 7,
        detail: "1-Hour Exercise: Shoot your first commercial",
      },
    ],
  },
  {
    id: 13,
    class: "fdfm__panel-light",
    img: Day13Image,
    alt: "Day 13",
    name: "day13",
    title: "Day 13: Edit Your Commercial",
    details: [
      {
        id: 1,
        detail:
          "How to edit interview based commercials and utilizing story in your messaging",
      },
      {
        id: 2,
        detail:
          "Our 8-Step editing workflow, walking you through our entire process from start to finish",
      },
      {
        id: 3,
        detail: "Watch us edit the 14-day filmmaker commercial",
      },
      {
        id: 4,
        detail: "Master your creative editing process",
      },
      {
        id: 5,
        detail: "1-Hour Exercise: Edit your first commercial",
      },
    ],
  },
  {
    id: 14,
    class: "fdfm__panel-light-gray",
    img: Day14Image,
    alt: "Day 14",
    name: "day14",
    title: "Day 14: Organic Distribution",
    details: [
      {
        id: 1,
        detail:
          "How to setup & optimize your social media profiles for consistent content creation",
      },
      {
        id: 2,
        detail: "Creative ways to organically distribute video content ",
      },
      {
        id: 3,
        detail:
          "Maximizing the effectiveness of every video with basic organic copywriting",
      },
      {
        id: 4,
        detail:
          "1-Hour Exercise: Customize & prime your social media channels, release your first commercial and write your second script",
      },
    ],
  },
]
