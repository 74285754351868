import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import styled from "styled-components"
import { AiFillYoutube } from "react-icons/ai"

export default function FdfmDaySection({ day }) {
  return (
    <>
      <section key={day.id} className={day.class}>
        <Container className={day.class}>
          <Row>
            <Spacer1 />
          </Row>
          <Row>
            <Col md={6}>
              <div className="fdfm-section__title-container">
                <h1 className="fdfm-section__title-h1">{day.title}</h1>
              </div>
              <div className="fdfm-section__list-container">
                <ul className="fdfm-section__item-list-ul">
                  {day.details.map(item => (
                    <span
                      key={day.id * 50 + item.id * 1}
                      className="fdfm-section__item-list-li-container"
                    >
                      <li
                        className="fdfm-section__item-li"
                        key={day.id * 10 + item.id * 1}
                      >
                        <YoutubeIcon />
                        {item.detail}
                      </li>
                    </span>
                  ))}
                </ul>
              </div>
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center px-2 pt-3"
            >
              <BasicImage alt={day.alt} src={day.img} />
              {/* <Row>
                <Video
                  src={day.video}
                  type="video/mp4"
                  autoPlay
                  loop
                  muted
                  playsIinline
                />
              </Row> */}
            </Col>
          </Row>
          <Row>
            <Spacer1 />
          </Row>
        </Container>
      </section>
    </>
  )
}

const YoutubeIcon = styled(AiFillYoutube)`
  font-size: 35px;
  color: red;
  margin-right: 7px;
  padding: 0;

  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`
const BasicImage = styled.img`
  width: 100%;
  border: 0;
  margin: 0 10px;
  box-shadow: rgb(136, 136, 136) 0px 0px 75px 0px;
  border-radius: 5px;
`
const Spacer1 = styled.div`
  height: 20px;

  @media screen and (max-width: 768px) {
    margin: 0;
    border: 0;
    padding: 0;
    height: 40px;
  }
`
// const Video = styled.video`
//   width: 100%;
//   border: 0;
//   margin: 0 10px;
//   box-shadow: rgb(136, 136, 136) 0px 0px 75px 0px;
//   border-radius: 25px;
// `
